import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiGetAllHedgeGraphData, apiGetValuationGraphData } from '../../store';
import TradeChart from './TradeChart';
import ValuationChart from './ValuationChart';
import Hedge from './Hedge';
import OverallStatistics from './OverallStatistics';

// Define an array to hold the names of the months
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const beginningYear = 2024;

const yearsList = [2024,2025,2026,2027,2028,2029];

//for (let i = beginningYear; i <= new Date().getFullYear(); i++) {
//    yearsList.push(i);
//}

const Hedges = () => {
    const dispatch = useDispatch();
    const portfolioKey = 'selectedPortfolioForHedges';
    const unitKey = 'selectedUnitForHedges';

    let initialPortfolio = { uuid: '', name: '', startYear: beginningYear };
    let initialUnit = 'mw';
    let initialMonth = 0;

    const { portfolio } = useSelector((state) => state.portfolio);
    const { hedge, valuation, isGetHedgeLoading, isGetValuationLoading } =
        useSelector((state) => state.hedges);

    const [selectedYear, setSelectedYear] = useState(beginningYear);
    const [selectedMonth, setSelectedMonth] = useState(0);

    const [selectedPortfolio, setSelectedPortfolio] =
        useState(initialPortfolio);

    const portfolioFromLocalStorage = localStorage.getItem(portfolioKey);

    const unitFromLocalStorage = localStorage.getItem(unitKey);

    const [selectedUnit, setSelectedUnit] = useState(
        unitFromLocalStorage ?? initialUnit
    );

    const [hedgeGraphData, setHedgeGraphData] = useState([]);
    const [valuationGraphData, setValuationGraphData] = useState([]);

    let monthArray = [];

    useEffect(() => {
        // The portfolio is already present in the state but takes time loading
        if (portfolio.length > 0) {
            // The portfolio from local storage can be null when the field is unchanged or the webapp is opened for the first time
            // This condition is only used on the first run
            if (portfolioFromLocalStorage === null) {
                let singlePortfolio = {
                    uuid: portfolio[0].uuid,
                    name: portfolio[0].name,
                    startYear: portfolio[0].profile_years[0]
                };

                // Used for the state of the select element
                setSelectedPortfolio(singlePortfolio);

                dispatch(apiGetAllHedgeGraphData(singlePortfolio));

                dispatch(
                    apiGetValuationGraphData({
                        uuid: singlePortfolio.uuid,
                        name: singlePortfolio.name,
                        year: beginningYear,
                        month: months[initialMonth]
                    })
                );
            } else {
                const portfolioJSON = JSON.parse(portfolioFromLocalStorage);
                let singlePortfolio = {
                    uuid: portfolioJSON.uuid,
                    name: portfolioJSON.name,
                    startYear: portfolioJSON.startYear
                };

                setSelectedPortfolio(singlePortfolio);
                dispatch(apiGetAllHedgeGraphData(singlePortfolio));

                dispatch(
                    apiGetValuationGraphData({
                        uuid: portfolioJSON.uuid,
                        name: portfolioJSON.name,
                        year: beginningYear,
                        month: months[initialMonth]
                    })
                );
            }
        }
    }, [portfolio]);

    if (selectedPortfolio.startYear !== 0) {
        monthArray = generateMonthList(selectedPortfolio.startYear);
    }

    useEffect(() => {
        if (selectedUnit === 'mw') {
            setHedgeGraphData([
                {
                    name: 'Time',
                    data: monthArray
                },
                {
                    type: 'bar',
                    name: 'Year',
                    data: hedge.dataMw.year || []
                },

                {
                    type: 'bar',
                    name: 'Month',
                    data: hedge.dataMw.month || []
                },
                {
                    type: 'bar',
                    name: 'Quarter',
                    data: hedge.dataMw.quarter || []
                },
                {
                    type: 'bar',
                    name: 'GY',
                    data: hedge.dataMw.gy || []
                },
                {
                    type: 'bar',
                    name: 'Others',
                    data: hedge.dataMw.others || []
                },
                {
                    type: 'bar',
                    name: 'Summer',
                    data: hedge.dataMw.summer || []
                },
                {
                    type: 'bar',
                    name: 'Winter',
                    data: hedge.dataMw.winter || []
                },
                {
                    type: 'bar',
                    name: 'Transactions',
                    data: hedge.dataMw.transactions || []
                },
                {
                    type: 'line',
                    name: 'Demand',
                    data: hedge.dataMw.demandOne || []
                },
                {
                    type: 'line',
                    name: 'Demand 2',
                    data: hedge.dataMw.demandTwo || []
                },
                {
                    type: 'line',
                    name: 'Demand 3',
                    data: hedge.dataMw.demandThree || []
                },
                {
                    type: 'line',
                    name: 'Demand 4',
                    data: hedge.dataMw.demandFour || []
                },
                {
                    type: 'line',
                    name: 'MEV',
                    data: hedge.dataMw.mev || []
                }
            ]);
        } else {
            setHedgeGraphData([
                {
                    name: 'Time',
                    data: monthArray
                },
                {
                    type: 'bar',
                    name: 'Year',
                    data: hedge.data.year || []
                },

                {
                    type: 'bar',
                    name: 'Month',
                    data: hedge.data.month || []
                },
                {
                    type: 'bar',
                    name: 'Quarter',
                    data: hedge.data.quarter || []
                },
                {
                    type: 'bar',
                    name: 'GY',
                    data: hedge.data.gy || []
                },
                {
                    type: 'bar',
                    name: 'Others',
                    data: hedge.data.others || []
                },
                {
                    type: 'bar',
                    name: 'Summer',
                    data: hedge.data.summer || []
                },
                {
                    type: 'bar',
                    name: 'Winter',
                    data: hedge.data.winter || []
                },
                {
                    type: 'bar',
                    name: 'Transactions',
                    data: hedge.data.transactions || []
                },
                {
                    type: 'line',
                    name: 'Demand',
                    data: hedge.data.demandOne || []
                },
                {
                    type: 'line',
                    name: 'Demand 2',
                    data: hedge.data.demandTwo || []
                },
                {
                    type: 'line',
                    name: 'Demand 3',
                    data: hedge.data.demandThree || []
                },
                {
                    type: 'line',
                    name: 'Demand 4',
                    data: hedge.data.demandFour || []
                },
                {
                    type: 'line',
                    name: 'MEV',
                    data: hedge.data.mev || []
                }
            ]);
        }
    }, [selectedUnit, hedge]);

    useEffect(() => {
        if (selectedUnit === 'mw') {
            setValuationGraphData([
                {
                    type: 'bar',
                    name: 'Valuation',
                    data: valuation.dataMw || []
                }
            ]);
        } else {
            setValuationGraphData([
                {
                    type: 'bar',
                    name: 'Valuation',
                    data: valuation.data || []
                }
            ]);
        }
    }, [selectedUnit, valuation]);

    const handlePortfolioChange = (event) => {
        // Get the value from uuid
        const uuid = event.target.value;

        // Since user can select None that has empty string as value,
        // Only execute this if it's not an empty string
        if (uuid !== '') {
            // Get the single portfolio from the list of portfolio(s) using the uuid
            const extractedPortfolio = portfolio.find((p) => p.uuid === uuid);

            const singlePortfolio = {
                uuid: extractedPortfolio.uuid,
                name: extractedPortfolio.name,
                startYear: extractedPortfolio.profile_years[0]
            };

            // Save the value of selected portfolio to the localStorage
            localStorage.setItem(portfolioKey, JSON.stringify(singlePortfolio));

            // change the single portfolio value to be the selected portfolio value
            setSelectedPortfolio(singlePortfolio);

            // Fetch the data using the singlePortfolio's data
            dispatch(apiGetAllHedgeGraphData(singlePortfolio));
        }
    };

    const handleUnitChange = (event) => {
        let selectedUnit = event.target.value;
        localStorage.setItem(unitKey, selectedUnit);
        setSelectedUnit(selectedUnit);
    };

    const handleYearChange = (event) => {
        let selectedYear = event.target.value;
        setSelectedYear(selectedYear);
        dispatch(
            apiGetValuationGraphData({
                uuid: selectedPortfolio.uuid,
                name: selectedPortfolio.name,
                year: selectedYear,
                month: months[selectedMonth]
            })
        );
    };

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        setSelectedMonth(selectedMonth);
        dispatch(
            apiGetValuationGraphData({
                uuid: selectedPortfolio.uuid,
                name: selectedPortfolio.name,
                year: selectedYear,
                month: months[selectedMonth]
            })
        );
    };

    return (
        <div className="flex flex-col gap-8">
            <div className="w-full h-[600px] bg-white rounded p-8">
                <TradeChart
                    consumptionData={hedgeGraphData}
                    monthArray={monthArray}
                    handlePortfolioChange={handlePortfolioChange}
                    handleUnitChange={handleUnitChange}
                    selectedPortfolio={selectedPortfolio}
                    selectedUnit={selectedUnit}
                    portfolio={portfolio}
                    isLoading={isGetHedgeLoading}
                />
            </div>
            <Hedge selectedPortfolio={selectedPortfolio} />
            <ValuationChart
                months={months}
                selectedPortfolio={selectedPortfolio}
                yearsList={yearsList}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                onYearChange={handleYearChange}
                onMonthChange={handleMonthChange}
                valuation={valuationGraphData}
                isGetValuationLoading={isGetValuationLoading}
            />
            <OverallStatistics selectedPortfolio={selectedPortfolio} />
        </div>
    );
};

export default Hedges;

function generateMonthList(startYear) {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    const currentYear = new Date().getFullYear();
    const result = [];

    for (let i = startYear; i <= 2029; i++) {
        for (let month of months) {
            result.push(`${month} ${i}`);
        }
    }

    return result;
}
